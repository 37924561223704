import React from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';
import { Fade } from 'react-awesome-reveal';
import PositionCard from './PositionCard';

import * as styles from './positions.module.scss';

const Positions = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query Positions {
      allStrapiPosition {
        nodes {
          title
          subtitle
          contractType
          location
          path
          content {
            type
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            markdown {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          locale
        }
      }
    }
  `);

  // const sortAsc = (a, b) => a.order - b.order;
  // const openPositions = data.allStrapiPosition.nodes
  //   .filter((node) => node.locale === intl.locale)
  //   .sort(sortAsc);

  // const navigateToPositionDetails = (position) => {
  //   position.content.map((contentBlock) =>
  //     contentBlock.markdown !== null ? navigate(`/${position.path}`) : null
  //   );
  // };

  return (
    <div className="text-center mt-4">
      <h2>{intl.formatMessage({ id: 'positions.title' })}</h2>
      <hr className="rectangle" />
      <p className="mb-4">{intl.formatMessage({ id: 'positions.description' })}</p>
      <Container fluid>
        <p className="mb-4 text-center">{intl.formatMessage({ id: 'positions.noOpenPosition' })}</p>
        {/* {openPositions.length === 0 ? (
          <p className="mb-4 text-center">
            {intl.formatMessage({ id: 'positions.noOpenPosition' })}
          </p>
        ) : (
          openPositions.map((position, index) => (
            <Col md={{ span: 6, offset: 3 }} onClick={() => navigateToPositionDetails(position)}>
              <Fade triggerOnce delay={index * 60}>
                <PositionCard position={position} key={index} />
              </Fade>
            </Col>
          ))
        )} */}
      </Container>
    </div>
  );
};

export default Positions;
